import Sortable from 'sortablejs';

export default {
  data(){
    return {
      // sortable: false, fixed: false, options: [] 
      vxeHead: [
        { type: 'seq', width: '60' },
        { type: 'checkbox', width: '60' },
        { field: 'name', title: 'Name' },
        { field: 'sex', title: 'Sex', sortable: true, },
      ]
    };
  },
  mounted() {
    this.columnDrop();
  },
  methods: {
    /**
    * @description 列宽拖拽
    */
    headerDragend(newWidth, oldWidth, column, event) {
      console.log(newWidth, oldWidth, column, event)
      // this.$emit('headerDragend', {newWidth, oldWidth, column, event})
    },
    /**
     * @description 列拖拽
    */
    columnDrop() {
      const wrapperTr = document.querySelector('.vxe-table--header tr')

      console.log(wrapperTr)
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        ghostClass: 'sortable-ghost',
        onEnd: async (evt) => {
          const {newIndex, oldIndex, event} = evt;
          const dropColumn = JSON.parse(JSON.stringify(this.vxeHead));

          const oldItem = dropColumn[oldIndex];
          dropColumn.splice(oldIndex, 1);
          dropColumn.splice(newIndex, 0, oldItem);
          
          // await this.$emit('headerDroped', dropColumn);

          console.log(dropColumn);
          this.$refs.toolbar_demo.reloadColumn(dropColumn)
          this.vxeHead = dropColumn;
          
        }
      })
    },
  }
}