export const getTableColumn = vm => {
  return {
    tableColumn: [
      { label: "序号", type: "index", value: "index",'showOverflowTooltip': true, width: "100"},
      { label: "", type: "select", value: "index",'showOverflowTooltip': true, width: "50"},
      { 
        label: "预警名称1", 
        value: "1", 
        sortable: true,
        sortBy: ['1', '2'],
        'showOverflowTooltip': true,
        width: "100"
      },
      { label: "开始预警时间2", value: "2", 'showOverflowTooltip': true, },
      { label: "结束预警时间3", value: "3", 'showOverflowTooltip': true, },
      { label: "是否邮件通知4", value: "4", 'showOverflowTooltip': true, },
      { label: "邮件通知角色5", value: "5", 'showOverflowTooltip': true, },
      { label: "邮件通知对象6", value: "6", 'showOverflowTooltip': true, },
      { label: "备注7", value: "7", 'showOverflowTooltip': true, },
      { label: "状态8", value: "8", 'showOverflowTooltip': true,},
      {
        label: "操作",
        value: "9",
        'showOverflowTooltip': true,
        type: "render",
        render: (h)=> {
          return (
            <div>
              <el-button type="text">详情</el-button>
              <el-button type="text">详情</el-button>
              <el-button type="text">详情</el-button>
            </div>
          );
        }
      }
    ]
  }
}