import { getTableColumn } from './configs/tableConfig';
import { exportByFront } from '@utils/utils';
import dragMixin from './drag';

export default {
  name: "Table",
  mixins: [dragMixin],
  data () {
    const tableColumn = getTableColumn(this)
    return {
      importData: [], // table 数据
      dropColumn: tableColumn.tableColumn, // 拖拽用的配置
      tableColumn: tableColumn.tableColumn, // 原本的表格配置
      allAlign: null,
      tableData: [
        { id: 10001, name: 'Test1', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃' },
        { id: 10002, name: 'Test2', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou' },
        { id: 10003, name: 'Test3', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai' },
        { id: 10004, name: 'Test4', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai' },
        { id: 10005, name: 'Test5', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃' },
        { id: 10006, name: 'Test6', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou' },
        { id: 10007, name: 'Test7', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai' },
        { id: 10008, name: 'Test8', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai' },
        { id: 10009, name: 'Test9', role: 'Develop', sex: 'Man', age: 28, address: 'vxe-table 从入门到放弃' },
        { id: 100010, name: 'Test10', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou' },
        { id: 100011, name: 'Test11', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai' },
        { id: 100012, name: 'Test12', role: 'Designer', sex: 'Women ', age: 24, address: 'Shanghai' }
      ],
      loading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 100
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData () {
      let res = [];
      for (let i = 0; i < 20; i++) {
        let obj = {};
        obj.index = i + 1;
        obj['1'] = `${i+1}`;
        obj['2'] = `key for ${i+1} key for ${i+1} key for ${i+1} key for ${i+1} key for ${i+1} key for ${i+1} key for ${i+1} key for ${i+1}`;
        obj['3'] = `key for 3`;
        obj['4'] = `key for 4`;
        obj['5'] = `key for 5`;
        obj['6'] = `key for 6`;
        obj['7'] = `key for 7`;
        obj['8'] = `${i}`;

        res.push(obj);
      }
      this.importData = res;
    },
    /**
     * @description 表头拖拽完变更表头宽度后 用于保存个人个性配置
    */
    headerDragend (params) {
      const {newWidth, oldWidth, column, event} = params;

      console.log(column)

      const dropColumn = this.dropColumn;
      dropColumn.forEach(thead => {
        if (thead.value === column.property) {
          thead.width = `${newWidth}`
        }
      });
      
      console.log(JSON.parse(JSON.stringify(dropColumn)))
    },
    /**
     * @description 表头拖拽完顺序后 用于保存个人个性配置
    */
    headerDroped(params) {
      this.dropColumn = params;
    },
    /**
     * 导出
    */
    handleExport() {
      exportByFront('文件名称', this.dropColumn, this.importData);
    },
    /**
     * 
    */
    handleTableConfig() {
      console.log(this.dropColumn)
    },
    // 单个勾选/取消
    selectChangeEvent({ checked, records }){
      console.log(checked ? '勾选事件' : '取消事件', records)
    },
    // 拖拽选中
    checkboxRangeEnd({ records, reserves, $event }){
      console.log('选中的值', records)
    },
    // 排序
    sortAgeMethod({ column, property, order }) {
      console.info(property, order)
    },
    // 单列过滤
    filterAgeMethod({ value, row, column }){
      return row.age >= value
    },
    headerCellClickEvent ({ column }) {
      console.log(`表头单元格点击${column.title}`)
    },
    headerCellDBLClickEvent ({ column }) {
      console.log(`表头单元格双击${column.title}`)
    },
    headerCellContextMenuEvent ({ column }) {
      console.log(`右键列 ${column.title}`)
    },
    cellClickEvent ({ column }) {
      console.log(`单元格点击${column.title}`)
    },
    cellDBLClickEvent ({ column }) {
      console.log(`单元格双击${column.title}`)
    },
    cellContextMenuEvent ({ row }) {
      console.log(`右键行 ${row.name}`)
    },
    scrollEvent ({ scrollTop, scrollLeft }) {
      // console.log(`滚动事件scrollTop=${scrollTop} scrollLeft=${scrollLeft}`)
    },
    checkColumnMethod ({ column }) {
      if (column.property === 'role') {
        return false
      }
      return true
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      console.log({ currentPage, pageSize })
    }
  }
}